@import "../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";

.title {
  margin: 2rem 0;
  font-weight: bold;
  color: #00b19f;
  margin-top: 0px;
}

.slide {
  background: white !important;
}

.carousel .control-prev.control-arrow::before {
  border-right: 8px solid #00b19f;
}

.carousel .control-next.control-arrow::before {
  border-left: 8px solid #00b19f;
}

.div-carousel {
  display: flex;
}

.div-carousel .image {
  width: 60%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.div-carousel .info {
  max-width: 40%;
  padding: 20px;
}

.div-carousel .info h3 {
  font-weight: 200;
  color: #00b19f;
}

.div-carousel .info p {
  max-height: 145px;
  overflow-y: hidden;
  margin: 0;
}

@media (max-width: 1200px) {
  .div-carousel {
    display: flex;
    flex-direction: column;
  }

  .div-carousel .image {
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .div-carousel .info {
    max-width: 100%;
    padding: 20px;
    height: auto;
  }

  .div-carousel .info h3 {
    font-weight: 200;
    color: #00b19f;
    margin: 0;
  }

  .div-carousel .info p {
    max-height: 145px;
    overflow-y: hidden;
    margin: 0;
  }
}

.carousel .control-dots {
  position: inherit;
  justify-content: center;
  display: flex;
}

.control-dots {
  padding: 0 !important;
}

.news {
  margin: auto;
  padding: 5rem 0;
}

.outline-grey {
  outline: solid 1px #dddddd;
}

.line {
  opacity: 0;
  margin: 0;
  width: 100%;
}

.white-bg {
  background-color: white;
}
