.bg {
  background-image:url("../../../assets/banner.jpg");
  height: 70vh;
  background-position: 15%;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;                                              

}
.bg h1{                                                                                      
  color: white;  
  font-weight: bolder;
  text-align: left;
}
.bg h5{
  color: white;
  max-width: 400px;
  text-align: left;                                                                      
}

@media (max-width: 784px){
  .bg h1{
    font-size: 24pt;
  }

  .bg h5{
    font-size: 12pt;
  }

  .bg{
    height: 100vh;
  }
}

