.table-of-contents {
  padding: 0;
}

.side-table {
  position: absolute;
  left: 900px;
  margin-left: 1rem;
  padding-left: 2rem;
}

.side-bar-table {
  position: relative;
  top: 112px;
  padding: 0 2rem;
}

.side-bar-table .icone {
  width: 20px;
  height: 20px;
}

.table-of-contents a {
  color: #979797;
}

.table-of-contents a:hover {
  color: #00b19f;
}

.table-wrapper {
  position: sticky;
  top: 112px;
}

.project-list {
  margin: auto;
  max-width: 900px;
}

.open-side {
  position: sticky;
  top: 49px;
  right: 30px;
  float: right;
  font-size: 20pt;
  color: white !important;
  margin-top: -5px;
  margin-right: 10px;
  z-index: 4;
  display: none;
}

.side-bar {
  display: none;
}

.zindex {
  z-index: 3;
}

.card-project {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;
  justify-content: space-between;
  margin-bottom: 90px;
  padding: 18px 36px;
  width: 335px;
  border-radius: 8px;
  position: relative;
  padding-top: 50px;
}

.card-project p,
h4 {
  max-width: 100%;
  text-align: -webkit-center;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  display: -webkit-box; /*As linhas abaixo foram tiradas desse método weird https://css-tricks.com/line-clampin/*/
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-bottom: 5px; /*Evita que a ultima linha não seja cortada verticalmente*/
}

.card-image {
  max-width: 100%;
  max-height: 100px;
  margin-bottom: 64px;
  z-index: 2;
}

.card-image-background {
  background-color: #00b19f;
  width: 180px;
  height: 180px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 15px;
  z-index: 1;
  border-radius: 50%;
}

.modal-button {
  margin: 0 auto;
  color: #00b19f;
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
  cursor: pointer;
  transition: 400ms;
}

.modal-button:hover {
  color: #017e71;
}

button.close {
  color: #f00;
}

button.close:hover {
  color: rgb(204, 2, 2);
}

@media (max-width: 1180px) {
  .side-table {
    display: none;
  }

  .side-bar {
    display: initial;
  }

  .open-side {
    display: initial;
  }
}

@media (max-width: 784px) {
  .text-content {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .card-project {
    margin: 3rem 0.2rem 0 0.2rem;
  }

  .cards-projects {
    padding-bottom: 50px;
  }
}
