.collapseBtn {
  width: 100%;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  color: white;
  background: #2e2e2e;
  margin-top: 5px;
}
.contentBtn {
  align-items: flex-start;
  display: flex;
  font-weight: 350;
}
.mob {
  text-align: left;
  background: white;
  margin-bottom: 5px;
}
.docItem {
  display: flex;
  width: 100%;
  padding: 5px;
  justify-content: space-between;
}

.docIcon {
  color: red;
  line-height: 1;
}
.collapseIcon {
  color: white;
}

.divloader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
