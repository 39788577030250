.project {
  max-width: 900px;
}

.anchor {
  display: block;
  position: relative;
  top: -56px;
  visibility: hidden;
}

.name {
  color: white;
  background-color: #212121;
  padding: 0.3rem 2rem;
  z-index: 1;
  margin-bottom: 0;
}

.project .text-content {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.side-by-side {
  flex: 1;
  margin-right: 0.2rem;
}

.links {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 784px) {
  .name {
    position: sticky;
    top: 56px;
  }

  .project .text-content {
    padding-top: 4rem;
  }
}

.circle-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-image span {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 200px;
  height: 200px;
  background-color: #2bbbad;
}
.circle-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
